import * as React from 'react';
import * as Utils from '../lib/utils';
import * as Boilerplate from '../lib/boilerplate';
import { SvgSnake } from '../images/snake';
import { SvgChef } from '../images/chef';
import { SvgSinging } from '../images/singing';
import * as styles from './index.scss';

type PageLinkProps = {
    url: string;
    label: string;
    SvgComponent: React.ComponentType<{}>;
};

function PageLink(props: PageLinkProps) {
    return (
        <a href={props.url}>
            <props.SvgComponent />
            <div>{props.label}</div>
        </a>
    );
}

function IndexPage() {
    return (
        <Boilerplate.Boilerplate>
            <div className={styles.linksSection}>
                <PageLink url="snake.html" label="Snake" SvgComponent={SvgSnake} />
                <PageLink url="recipes.html" label="Recipes" SvgComponent={SvgChef} />
                <PageLink url="lyrics.html" label="Lyrics" SvgComponent={SvgSinging} />
            </div>
        </Boilerplate.Boilerplate>
    );
}

Utils.renderRoot(<IndexPage />);
