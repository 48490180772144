import React = require('react');
import { createRoot } from 'react-dom/client';

export function renderRoot(toRender: React.ReactElement) {
    window.addEventListener('load', () => {
        createRoot(document.getElementById('react-root')!).render(toRender);
    });
}

export function getAsyncData<TData extends {}>(url: string, onSuccess: (data: TData) => void) {
    fetch(url)
        .then(response => {
            if (!response.ok) {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                return;
            }

            response.json().then(onSuccess);
        })
        .catch(err => console.log('Fetch error', err));
}

export function useAsyncData<TData extends {}>(
    url: string
): [TData | null, React.Dispatch<React.SetStateAction<TData>>] {
    const [data, setData] = React.useState<TData | null>(null);
    const isFirstCall = React.useRef(true);

    if (isFirstCall.current) {
        getAsyncData<TData>(url, data => setData(data));
        isFirstCall.current = false;
    }

    return [data, setData];
}

type SuspenseResource<T> = { read: () => T };

/** Wraps the provided promise to be suitable for use with React Suspense. */
function wrapPromise<T>(promise: Promise<T>): SuspenseResource<T> {
    let status = 'pending';
    let response: T;

    const suspender = promise.then(
        res => {
            status = 'success';
            response = res;
        },
        err => {
            status = 'error';
            response = err;
        }
    );
    const read = () => {
        switch (status) {
            case 'pending':
                throw suspender;
            case 'error':
                throw response;
            default:
                return response;
        }
    };

    return { read };
}

const cache = new Map();

/** Fetches data from the provided URL, in a manner suitable for use with React Suspense. */
export function suspenseFetch<TData>(url: string, deps?: React.DependencyList): SuspenseResource<TData> {
    if (deps) {
        url = `${url}${url.includes('?') ? '&' : '?'}_d=${deps.join('-')}`;
    }
    if (!cache.has(url)) {
        cache.set(url, wrapPromise<TData>(fetch(url).then(res => (res.ok ? res.json() : undefined))));
    }
    return cache.get(url);
}

/** Fetches data from the provided URL with a specified transformation, in a manner suitable for use with React Suspense. */
export function suspenseFetchTransform<TData>(
    url: string,
    transform: (json: unknown) => TData
): SuspenseResource<TData> {
    if (!cache.has(url)) {
        cache.set(
            url,
            wrapPromise<TData>(
                fetch(url).then(res => {
                    return res.json().then(transform);
                })
            )
        );
    }
    return cache.get(url);
}

export function newUpdatedArray<TItem>(
    array: TItem[],
    itemSelector: null | ((item: TItem, index: number) => boolean),
    newItem: null | TItem
) {
    const matchingIndex = itemSelector === null ? -1 : array.findIndex(itemSelector);

    if (matchingIndex === -1 && newItem !== null) {
        return [...array, newItem];
    }

    const before = array.slice(0, matchingIndex);
    const after = matchingIndex === array.length - 1 ? [] : array.slice(matchingIndex + 1);
    const item = newItem === null ? [] : [newItem];
    return [...before, ...item, ...after];
}

export function useCounter() {
    const [value, setValue] = React.useState(0);
    return {
        value: value,
        increment: () => setValue(prev => prev + 1)
    };
}

export function isNotNullOrUndefined<TValue>(value: TValue): value is NonNullable<TValue> {
    return value !== null && value !== undefined;
}

export function classNames(...classes: (string | null | undefined | Record<string, unknown>)[]) {
    return classes
        .filter(isNotNullOrUndefined)
        .map(arg => {
            return typeof arg === 'string'
                ? arg.trim()
                : Object.entries(arg)
                      .filter(([_, value]) => value)
                      .map(([key]) => key)
                      .join(' ');
        })
        .filter(str => str !== '')
        .join(' ');
}

function request<TResponse>(
    url: string,
    method: 'GET' | 'POST',
    body: {} | undefined,
    onSuccess: (response: TResponse) => void
) {
    fetch(url, {
        method: method,
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        body: body === undefined ? undefined : JSON.stringify(body)
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Response status ${response.status}`);
            }
            return response.json();
        })
        .then(onSuccess)
        .catch(error => {
            console.error(error);
        });
}

export function get<TResponse>(url: string, onSuccess: (response: TResponse) => void) {
    return request(url, 'GET', undefined, onSuccess);
}

export function post<TResponse>(url: string, data: {}, onSuccess: (response: TResponse) => void) {
    return request(url, 'POST', data, onSuccess);
}

export function sortArray<TItem>(array: TItem[], ...selectors: ((item: TItem) => string | number | boolean)[]) {
    const clone = [...array];
    clone.sort((a, b) => {
        for (let i = 0; i < selectors.length; i++) {
            const aValue = selectors[i](a);
            const bValue = selectors[i](b);

            if (aValue !== bValue) {
                return aValue < bValue ? -1 : 1;
            }
        }

        return 0;
    });
    return clone;
}

/** Returns a shuffled copy the provided array. */
export function shuffleArray<TItem>(array: TItem[]) {
    array = [...array];
    // https://stackoverflow.com/a/12646864
    for (let i = array.length - 1; i >= 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

/** Returns an array consisting of the provided one duplicated the specified number of times. */
export function duplicateArray<TItem>(array: TItem[], quantity: number) {
    if (quantity < 0) {
        throw new Error('Invalid quantity');
    }
    return Array(quantity).fill(array).flat();
}

export function toDictionary<TKey extends string | number, TValue>(
    array: TValue[],
    keySelector: (item: TValue) => TKey
): Record<TKey, TValue> {
    const result = {} as Record<string | number, TValue>;
    array.forEach(item => {
        result[keySelector(item)] = item;
    });
    return result;
}

export function toMappedDictionary<TItem, TKey extends string | number, TValue>(
    array: TItem[],
    keySelector: (item: TItem) => TKey,
    valueSelector: (item: TItem) => TValue
): Record<TKey, TValue> {
    const result = {} as Record<string | number, TValue>;
    array.forEach(item => {
        result[keySelector(item)] = valueSelector(item);
    });
    return result;
}

export function enumKeys<TEnum extends {}>(e: TEnum): Array<TEnum[keyof TEnum]> {
    /**
     * The entries are ['label1', 'value1'] for string enums, or ['label1', 1] and ['1', 'label1'] for number ones.
     * We filter out the second kind for number ones by looking up the value as a key and finding a number, then in
     * either case map to the value which is the string or number respectively.
     */
    function enumPredicate(entry: [string, unknown]): entry is [string, TEnum[keyof TEnum]] {
        return typeof e[entry[1] as keyof TEnum] !== 'number';
    }
    return Object.entries(e)
        .filter(enumPredicate)
        .map(entry => entry[1]);
}

export function sumArray(array: number[]) {
    return array.reduce((total, current) => total + current, 0);
}

/** Returns the provided object without the specified property. */
export function withoutProperty<TObj extends {}, TKey extends keyof TObj>(
    obj: TObj,
    propertyName: TKey
): Omit<TObj, TKey> {
    const { [propertyName]: _, ...rest } = obj;
    return rest;
}

type EqualityCheck<TItem> = (item1: TItem, item2: TItem) => boolean;

export class DistinctArray<TItem> {
    private items: TItem[];
    private areEqual: EqualityCheck<TItem>;

    constructor(initialItems?: TItem[], areEqual?: EqualityCheck<TItem>) {
        this.items = initialItems?.slice() ?? [];
        this.areEqual = areEqual ?? ((one, two) => one === two);

        Object.getOwnPropertyNames(Object.getPrototypeOf(this))
            .filter(propertyName => propertyName !== 'constructor')
            .forEach((propertyName: keyof DistinctArray<TItem>) => {
                const propertyValue = this[propertyName];
                if (typeof propertyValue === 'function') {
                    this[propertyName] = propertyValue.bind(this);
                }
            });
    }

    indexOf(item: TItem) {
        return this.items.findIndex(innerItem => this.areEqual(innerItem, item));
    }

    includes(item: TItem) {
        return this.indexOf(item) !== -1;
    }

    add(item: TItem) {
        const index = this.indexOf(item);
        return index !== -1 ? this : new DistinctArray([...this.items, item], this.areEqual);
    }

    remove(item: TItem) {
        const index = this.indexOf(item);
        return index === -1
            ? this
            : new DistinctArray([...this.items.slice(0, index), ...this.items.slice(index + 1)], this.areEqual);
    }

    setIncluded(item: TItem, included: boolean) {
        return (included ? this.add : this.remove)(item);
    }

    getItems() {
        return this.items.slice();
    }

    count() {
        return this.items.length;
    }
}

export function rangeArray(start: number, end: number, stepSize = 1): number[] {
    if (stepSize < 0) {
        throw new Error();
    }
    const length = Math.floor(Math.abs(end - start) / stepSize) + 1;
    stepSize = start <= end ? stepSize : -stepSize;
    return [...Array(length).keys()].map(index => start + index * stepSize);
}

export type ArrayType<T> = T extends Array<infer TItem> ? TItem : never;

export class UnexpectedValueError extends Error {
    public constructor(value: never) {
        super(`Unexpected value ${value}`);
    }
}

export function useOnMount(callback: React.EffectCallback) {
    React.useEffect(callback, []);
}

/** Returns the provided date in yyyy-mm-dd format */
export function formatYyMmDd(date: Date) {
    return `${date.getFullYear()}-${twoDigit(date.getMonth() + 1)}-${twoDigit(date.getDate())}`;
}

/** Returns the provided date in dd m yyyy format. */
export function formatDdMmmYyyy(date: Date) {
    return date.toLocaleDateString(undefined, { day: '2-digit', month: 'long', year: 'numeric' });
}

function twoDigit(number: number) {
    return number >= 10 || number < 0 ? number.toString() : `0${number}`;
}

export function groupBy<TItem, TKey extends number | string>(items: TItem[], keySelector: (item: TItem) => TKey) {
    const grouped = {} as Record<TKey, TItem[]>;

    items.forEach(item => {
        const key = keySelector(item);
        let group = grouped[key];
        if (group === undefined) {
            group = grouped[key] = [];
        }
        group.push(item);
    });
    return grouped;
}

export function useEffectSkipFirst(callback: React.EffectCallback, dependencies?: React.DependencyList) {
    const firstUpdate = React.useRef(true);
    React.useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        return callback();
    }, dependencies);
}

export function distinctArray<TItem extends string | number>(items: TItem[]) {
    return [...new Set(items)];
}

export class Dictionary<TKey extends PropertyKey, TValue> {
    private keys: Record<TKey, true | undefined>;
    private record: Record<TKey, TValue>;

    constructor(record: Record<TKey, TValue> = {} as Record<TKey, TValue>) {
        this.record = record;
        this.keys = Object.fromEntries(Object.keys(record).map(key => [key, true])) as typeof this.keys;

        this.count = this.count.bind(this);
        this.containsKey = this.containsKey.bind(this);
        this.getValue = this.getValue.bind(this);
        this.getValueOrDefault = this.getValueOrDefault.bind(this);
        this.remove = this.containsKey.bind(this);
        this.set = this.set.bind(this);
    }

    /** Returns a Record<TKey, TValue> with an entry for each item in keys, and values taken from record.  */
    private getRecordForConstructor() {
        return Object.fromEntries(
            Object.entries(this.keys)
                .filter(([_key, value]) => value)
                .map(([key, _value]) => [key, this.record[key as TKey]])
        ) as Record<TKey, TValue>;
    }

    /** Returns whether the dictionary contains the provided key. */
    containsKey(key: TKey) {
        return this.keys[key] === true;
    }

    /** Returns the number of keys in the dictionary. */
    count() {
        return Object.entries(this.keys).filter(([_key, value]) => value).length;
    }

    /** Returns the value contained in the dictionary for the provided key - throws an error if the key is not present. */
    getValue(key: TKey) {
        if (!this.containsKey(key)) {
            throw new Error(`Missing key: ${key.toString()}`);
        }
    }

    /** Returns the value contained in the dictionary for the provided key, or the provided defaultValue if the key is not present. */
    getValueOrDefault(key: TKey, defaultValue: TValue) {
        return this.keys[key] ? this.record[key] : defaultValue;
    }

    /** Returns a copy of this dictionary without the specified key. Returns this dictionary if the key is already not present. */
    remove(key: TKey) {
        if (!this.containsKey(key)) {
            return this;
        }
        const forConstructor = this.getRecordForConstructor();
        delete forConstructor[key];
        return new Dictionary<TKey, TValue>(forConstructor);
    }

    /** Returns a copy of this dictionary with the specified key/value pair. Returns this dictionary if the key is already present with the specified value. */
    set(key: TKey, value: TValue) {
        if (this.keys[key] && this.record[key] === value) {
            return this;
        }
        const forConstructor = this.getRecordForConstructor();
        forConstructor[key] = value;
        return new Dictionary<TKey, TValue>(forConstructor);
    }

    /**
     * Returns a copy of this dictionary with the provided key and a value determined by the other two parameters along with whether the key is currently present.
     * Returns this dictionary if the key is already present with that value.
     */
    update(key: TKey, valueIfNotPresent: TValue, getNewValueIfPresent: (prevValue: TValue) => TValue) {
        const newValue = this.keys[key] ? getNewValueIfPresent(this.record[key]) : valueIfNotPresent;
        return this.set(key, newValue);
    }
}
