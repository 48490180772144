import * as React from 'react';

type Position = { x: number; y: number };
type Size = { width: number; height: number };

type TextProps = {
    string: string;
    centre: Position;
    size: Size;
    colour: string;
};

function Text(props: TextProps) {
    return (
        <text
            x={props.centre.x}
            y={props.centre.y}
            fill={props.colour}
            stroke="#333"
            strokeWidth={0.3}
            textLength={props.size.width}
            lengthAdjust="spacingAndGlyphs"
            style={{
                textAnchor: 'middle',
                dominantBaseline: 'central',
                fontSize: `${props.size.height}px`,
                fontWeight: 600
            }}
        >
            {props.string}
        </text>
    );
}

export function SvgLogo(props: { size: number }) {
    const size = 33;
    const halfSize = size / 2;
    const margin = 2;
    const radius = halfSize - margin;

    return (
        <svg width={props.size} height={props.size} viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg">
            <title>Orange and red logo</title>

            <path
                fill="#f00"
                d={`M ${halfSize} ${margin} A ${radius} ${radius} 0 0 0 ${halfSize} ${size - margin} Z`}
            />
            <path fill="#f60" d={`M ${halfSize} 2 A ${radius} ${radius} 0 0 1 ${halfSize} ${size - 2} Z`} />
            <circle fill="transparent" stroke="#333" cx={halfSize} cy={halfSize} r={radius} />
            <Text
                string="O"
                centre={{ x: margin + 0.55 * radius, y: halfSize }}
                size={{ width: 0.7 * radius, height: 0.95 * radius }}
                colour="#f60"
            />
            <Text
                string="R"
                centre={{ x: margin + 1.45 * radius, y: halfSize }}
                size={{ width: 0.7 * radius, height: 0.95 * radius }}
                colour="#f00"
            />
        </svg>
    );
}
