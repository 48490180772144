import * as React from 'react';
import { IdWithDisplayName } from '../../types/common';
import * as styles from './input.scss';
import { classNames } from './utils';

type InputProps = {
    type?: string;
    label?: string;
    value: string;
    onChange: (newValue: string) => void;
    onEnter?: () => void;
    className?: string;
    labelClassName?: string;
    maxLength?: number;
};

export function Input(props: InputProps) {
    const componentId = React.useId();
    return (
        <>
            {props.label && (
                <label htmlFor={componentId} className={props.labelClassName}>
                    {props.label}
                </label>
            )}
            <input
                id={componentId}
                type={props.type}
                className={props.className}
                value={props.value}
                onKeyUp={
                    !props.onEnter
                        ? undefined
                        : e => {
                              if (e.key === 'Enter') {
                                  props.onEnter?.();
                              }
                          }
                }
                onChange={e => props.onChange(e.currentTarget.value)}
                maxLength={props.maxLength}
            />
        </>
    );
}

type TextareaProps = {
    label?: string;
    value: string;
    onChange: (newValue: string) => void;
    className?: string;
};

export function Textarea(props: TextareaProps) {
    const componentId = React.useId();
    return (
        <>
            {props.label && <label htmlFor={componentId}>{props.label}</label>}
            <textarea
                id={componentId}
                className={props.className}
                value={props.value}
                onChange={e => props.onChange(e.currentTarget.value)}
            />
        </>
    );
}

type DropdownProps = {
    label?: string;
    value: number;
    onChange: (newValue: number) => void;
    options: IdWithDisplayName[];
    optionClassName?: (id: number) => string | undefined;
};

export function Dropdown(props: DropdownProps) {
    const componentId = React.useId();
    return (
        <>
            {props.label && <label htmlFor={componentId}>{props.label}</label>}
            <select
                id={componentId}
                value={props.value}
                onChange={e => props.onChange(parseInt(e.currentTarget.value, 10))}
            >
                {props.options.map(option => (
                    <option key={option.id} value={option.id} className={props.optionClassName?.(option.id)}>
                        {option.displayName}
                    </option>
                ))}
            </select>
        </>
    );
}

type CheckboxProps = {
    label?: string;
    labelClassName?: string;
    value: boolean;
    onChange: (newValue: boolean) => void;
};

export function Checkbox(props: CheckboxProps) {
    const componentId = React.useId();
    return (
        <>
            {props.label && (
                <label htmlFor={componentId} className={props.labelClassName}>
                    {props.label}
                </label>
            )}
            <input
                type="checkbox"
                className={styles.checkbox}
                id={componentId}
                checked={props.value}
                onChange={e => props.onChange(e.currentTarget.checked)}
            />
        </>
    );
}

type ButtonProps = {
    text: string;
    onClick: (button: HTMLButtonElement) => void;
    className?: string;
    disabled?: boolean;
    title?: string;
    tabIndex?: number;
};

export function Button(props: ButtonProps) {
    return (
        <button
            type="button"
            className={classNames(styles.textButton, props.className)}
            onClick={e => props.onClick(e.currentTarget)}
            title={props.title}
            disabled={props.disabled}
            tabIndex={props.tabIndex}
        >
            {props.text}
        </button>
    );
}

type RadioButtonsProps = {
    options: IdWithDisplayName[];
    value: number;
    onChange: (selected: number) => void;
    fieldSetName: string;
};

export function RadioButtons(props: RadioButtonsProps) {
    const componentId = React.useId();
    const radios = props.options.map(option => (
        <div key={option.id} className={styles.radioContainer}>
            <input
                type="radio"
                id={`${componentId}-${option.id}`}
                checked={option.id === props.value}
                onChange={() => props.onChange(option.id)}
            />
            <label htmlFor={`${componentId}-${option.id}`}>{option.displayName}</label>
        </div>
    ));

    return (
        <fieldset className={styles.radioFieldset}>
            <legend>{props.fieldSetName}</legend>
            {radios}
        </fieldset>
    );
}

export function SpacedInputs(props: React.PropsWithChildren<{ withBottomMargin?: boolean; horizontal?: boolean }>) {
    return (
        <div
            className={classNames(styles.spacedInputs, {
                [styles.withBottomMargin]: props.withBottomMargin,
                [styles.horizontal]: props.horizontal
            })}
        >
            {props.children}
        </div>
    );
}
