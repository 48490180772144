import * as React from 'react';
import { SvgLogo } from '../images/logo';
import { Button } from './input';
import * as styles from './boilerplate.scss';

export function Boilerplate(props: React.PropsWithChildren<{ onHiddenClick?: () => void }>) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <a href="/">
                    <SvgLogo size={64} />
                    <h1>Orange and Red</h1>
                </a>
                {props.onHiddenClick && (
                    <Button
                        className={styles.hiddenButton}
                        tabIndex={-1}
                        onClick={props.onHiddenClick}
                        aria-hidden
                        text=""
                    />
                )}
            </div>
            <div className={styles.content}>{props.children}</div>
            <div className={styles.footer} />
        </div>
    );
}
