import * as React from 'react';

export function SvgChef() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="707px"
            height="863.845px"
            viewBox="0 0 707 863.845"
            enableBackground="new 0 0 707 863.845"
            xmlSpace="preserve"
        >
            <g>
                <path
                    fill="#5DC19B"
                    stroke="#000000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.75,692.916V188.908
		c0.816-48.887,40.697-88.782,90.999-88.663h520.333c48.725-0.119,88.63,39.776,88.668,88.663v504.008H4.75L4.75,692.916z"
                />
                <g>
                    <path
                        fill="#640D0D"
                        d="M95.749,798.25c-49.091-0.955-88.201-40.065-88.999-89.036l0-14.299
			c39.937-0.01,649.849-0.16,695.195-0.165c3.51,36.657-9.777,60.395-21.658,73.916c-16.077,18.3-39.487,29.082-64.228,29.584
			H95.749z"
                    />
                    <path
                        d="M700.114,696.75c2.844,34.92-9.906,57.595-21.329,70.597c-15.703,17.873-38.57,28.407-62.743,28.903H95.79
			c-48.02-0.952-86.242-39.175-87.04-87.036v-12.299C62.898,696.901,643.167,696.759,700.114,696.75 M703.75,692.75
			c-27.5,0-699,0.166-699,0.166v16.33c0.816,50.121,40.697,90.025,90.999,91.004h520.333C664.807,799.271,711,759,703.75,692.75
			L703.75,692.75z"
                    />
                </g>
                <polygon
                    fill="#B34D32"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="
		490.082,100.245 490.082,165.575 476.088,165.575 476.088,100.245 490.082,100.245 	"
                />
                <path
                    fill="#B34D32"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M588.084,338.245
		c33.752-0.693,61.532-35.879,60.668-79.335c0.864-43.356-26.916-78.538-60.668-79.335c-34.77,0.797-62.549,35.979-62.996,79.335
		C525.535,302.365,553.314,337.551,588.084,338.245L588.084,338.245z"
                />
                <path
                    fill="#D9A699"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M625.415,179.575
		c33.829,0.797,61.608,35.979,60.669,79.335c0.939,43.456-26.84,78.642-60.669,79.335h-37.331
		c33.752-0.693,61.532-35.879,60.668-79.335c0.864-43.356-26.916-78.538-60.668-79.335v-79.33h14.004v79.33H625.415L625.415,179.575
		z"
                />
                <polygon
                    fill="#B34D32"
                    stroke="#000000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="616.082,100.245 
		616.082,179.575 602.088,179.575 602.088,100.245 616.082,100.245 	"
                />
                <path
                    fill="#B34D32"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M459.748,345.242
		c39.952,1.092,71.806-39.254,72.338-88.668c-0.532-50.111-32.386-90.462-72.338-90.999c-38.613,0.537-70.477,40.888-70.002,90.999
		C389.271,305.987,421.135,346.333,459.748,345.242L459.748,345.242z"
                />
                <path
                    fill="#D9A699"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M504.087,165.575
		c38.499,0.537,70.343,40.888,69.992,90.999c0.351,49.414-31.493,89.76-69.992,88.668h-44.339
		c39.952,1.092,71.806-39.254,72.338-88.668c-0.532-50.111-32.386-90.462-72.338-90.999v-65.331h16.34v65.331H504.087
		L504.087,165.575z"
                />
                <path
                    fill="#B34D32"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M357.086,314.907
		c35.157-0.028,62.938-35.21,62.994-79.33c-0.057-42.691-27.837-77.877-62.994-76.999c-33.382-0.878-61.158,34.308-60.669,76.999
		C295.928,279.697,323.704,314.879,357.086,314.907L357.086,314.907z"
                />
                <path
                    fill="#D9A699"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M394.418,158.578
		c35.224-0.878,63.004,34.308,62.994,76.999c0.01,44.12-27.771,79.302-62.994,79.33h-37.332c35.157-0.028,62.938-35.21,62.994-79.33
		c-0.057-42.691-27.837-77.877-62.994-76.999v-58.333h16.33v58.333H394.418L394.418,158.578z"
                />
                <polygon
                    fill="#B34D32"
                    stroke="#000000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="382.749,100.245 
		382.749,158.578 373.416,158.578 373.416,100.245 382.749,100.245 	"
                />
                <g>
                    <path
                        fill="#B34D32"
                        d="M583.631,793.835c-41.818,0-88.849-10.943-129.032-30.024c-38.42-18.244-65.982-41.876-73.729-63.215
			l-0.093-0.256l-0.021-0.271c-0.013-0.169-1.271-16.369-2.22-30.878c-46.712-1.627-96.554-27.912-97.059-28.182l-0.139-0.073
			l-0.125-0.096c-6.946-5.245-10.166-17.578-7.031-26.93c2.55-7.607,8.68-11.797,17.261-11.797c0.892,0,1.822,0.046,2.765,0.137
			c26.333,0.646,91.486,30.331,104.155,47.474l0.598,0.81l-0.293,0.962c-2.858,9.377,6.013,22.697,24.98,37.509
			c21.818,16.632,55.655,34.391,92.818,48.71c29.295,10.85,62.14,16.83,92.458,16.83c5.606,0,11.102-0.211,16.334-0.628
			c25.683-1.762,45.976-8.213,57.162-18.166l2.902,2.729c-1.248,1.589-30.696,39.014-42.434,47.079l-0.26,0.179l-0.303,0.09
			C624.477,791.141,604.729,793.835,583.631,793.835z"
                    />
                    <path
                        d="M291.442,604.113c0.844,0,1.727,0.044,2.644,0.135c25.858,0.579,90.22,29.822,102.667,46.664
			c-3.39,11.118,7.339,25.359,25.663,39.668c22.967,17.508,57.355,35.139,93.33,49c30.869,11.433,64.179,16.964,93.177,16.964
			c5.679,0,11.195-0.212,16.492-0.634c26.005-1.784,46.884-8.479,58.333-18.666c0,0-30.372,38.681-41.993,46.666
			c-18.434,5.487-38.121,7.925-58.124,7.925c-89.57,0-185.267-48.909-200.882-91.922c0,0-1.367-17.574-2.336-32.671
			c-47.301-0.986-97.996-27.998-97.996-27.998C272.105,631.457,270.35,604.111,291.442,604.113 M291.442,600.113
			c-9.371-0.001-16.354,4.795-19.157,13.158c-3.383,10.093,0.152,23.448,7.721,29.165l0.251,0.189l0.278,0.148
			c2.05,1.093,49.556,26.138,96.122,28.341c0.932,14.029,2.09,28.946,2.103,29.108l0.043,0.543l0.186,0.512
			c4.077,11.231,13.012,22.736,26.556,34.197c12.783,10.816,29.449,21.239,48.196,30.142c40.441,19.203,87.784,30.218,129.89,30.218
			c21.291,0,41.23-2.723,59.266-8.091l0.604-0.18l0.521-0.357c11.986-8.236,41.618-45.894,42.874-47.492l-5.806-5.459
			c-10.699,9.521-31.091,15.958-55.947,17.664c-5.224,0.415-10.665,0.624-16.219,0.624c-30.08,0-62.668-5.933-91.765-16.707
			c-36.983-14.252-70.631-31.906-92.317-48.438c-17.968-14.03-26.824-26.914-24.262-35.32l0.587-1.925l-1.196-1.618
			c-13.198-17.858-78.239-47.565-105.643-48.282C293.346,600.16,292.376,600.113,291.442,600.113L291.442,600.113z"
                    />
                </g>
                <path
                    fill="#DE3F20"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M625.415,755.91
		c-32.347,2.574-72.755-2.658-109.669-16.33c-35.975-13.861-70.363-31.492-93.33-49C453.025,682.339,558.423,679.13,625.415,755.91
		L625.415,755.91z"
                />
                <path
                    fill="#C89871"
                    stroke="#000000"
                    strokeWidth="3"
                    d="M422.416,690.58c30.609-8.241,136.007-11.45,202.999,65.33
		c26.005-1.784,46.884-8.479,58.333-18.666l0,0c7.672-20.166-40.598-46.133-51.336-53.662
		c-12.665-7.092-69.803-35.65-116.666-44.338c-51.014-8.099-100.735-6.627-118.993,11.668
		C393.363,662.03,404.092,676.271,422.416,690.58"
                />
                <path
                    fill="none"
                    stroke="#000000"
                    strokeWidth="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M702.414,709.246
		c2.298,50.121-37.607,90.025-86.332,91.004H95.749c-50.301-0.979-90.182-40.883-90.999-91.004V188.908
		c0.816-48.887,40.697-88.782,90.999-88.663h520.333c48.725-0.119,88.63,39.776,86.332,88.663V709.246L702.414,709.246z"
                />
                <g>
                    <path
                        fill="#AA4723"
                        stroke="#000000"
                        strokeWidth="3"
                        strokeMiterlimit={10}
                        d="M513.42,783.91
			c-17.816-5.065-34.863-11.27-50.601-18.355c-15.737-7.085-30.164-15.051-42.739-23.639c-2.145,10.615,2.241,32.557,14.005,39.658
			c-3.407-1.659-7.962-3.709-13.089-6.057c-21.371-9.788-52.679-24.766-52.242-38.273c-2.545,12.418-3.777,36.736,7.532,55.834
			c8.099,13.677,22.629,24.676,47.714,26.708c0.988,0.08,1.993,0.146,3.015,0.198c3.912,0.198,8.069,0.188,12.485-0.055
			c3.476-0.191,7.111-0.526,10.915-1.015c1.76,2.524,3.834,5.315,6.222,8.224c9.341,11.372,23.485,24.527,42.388,30.525
			c11.572,3.672,24.929,4.662,40.059,0.918c9.528-2.007,15.414-4.24,19.254-6.409c2.558-1.444,4.208-2.86,5.423-4.162
			c4.286-4.594,3.154-7.769,17.326-5.769c-9.558-7.079-21.184-10.458-31.075-9.349c-3.069,0.344-5.972,1.12-8.593,2.353
			c-7.908,3.008-17.434-5.811-21.595-16.869c-0.789-2.098-1.386-4.275-1.741-6.469c5.656,2.655,14.688,4.09,23.902,2.869
			c9.729-1.288,19.66-5.535,26.036-14.429c5.573-7.775,8.43-19.101,6.059-35.104c-4.912,6.982-12.258,10.137-20.168,11.574
			c-13.183,2.396-27.931,0.021-35.586,2.651C515.854,780.318,514.121,781.689,513.42,783.91L513.42,783.91z"
                    />
                    <path
                        fill="#BB6424"
                        d="M370.815,743.746c-0.269,8.287,22.81,22.43,39.693,30.216c4.003,1.846,8.219,3.48,12.178,4.962
			c4.964,1.857,9.49,3.455,12.913,4.604c-0.84-1.07-1.704-2.627-2.801-4.565c-6.446-8.146-11.063-23.556-10.515-32.19
			c1.316,0.388,2.86,0.874,4.746,1.566c10.623,6.808,22.854,13.642,34.896,19.078c13.663,6.165,32.693,12.817,50.688,18.063l0,0
			c1.103-1.485,2.946-2.38,5.323-2.818c0.783-0.145,1.617-0.228,2.488-0.295c1.547-0.298,3.43-1.549,6.175-2.003
			c6.253-1.035,17.097,0.047,25.994-1.189c2.2-0.306,4.254-0.715,6.021-1.307c5.54-1.872,11.837-5.677,14.568-9.559
			c1.778,12.002-2.597,24.771-6.919,30.641c-4.819,6.545-11.492,10.394-18.463,12.285c-2.485,0.675-5.318,1.117-8.252,1.34
			c-8.432,0.668-17.545-0.398-21.77-1.892c-0.656-0.232-1.389-0.235-2.08-0.106c0.131,2.087,0.424,4.349,1.027,6.588
			c0.267,0.988,0.582,1.975,0.98,2.93c3.572,8.563,12.371,16.809,19.666,16.011c1.789-0.195,3.637-0.453,5.511-0.569
			c0.986-0.062,1.993-0.1,3.014-0.134c5.977-0.198,12.347-0.513,17.999,0.692c4.672,0.996,10.762,2.984,13.854,5.275
			c-5.281-0.746-9.415-0.45-11.488,1.246c-1.857,1.52-2.889,3.135-4.928,4.883c-0.467,0.399-0.979,0.772-1.493,1.199
			c-1.024,0.85-2.267,1.638-3.862,2.413c-2.259,1.099-5.233,2.165-9.347,3.31c-2.753,0.755-5.786,1.435-9.185,2.156
			c-11.374,2.415-21.388,2.401-31.773-0.2c-2.153-0.539-4.246-1.134-6.27-1.811c-17.459-5.808-30.194-17.093-39.929-29.587
			c-1.791-2.181-4.92-6.146-6.239-8.04c-1.76,0.226-4.525-0.845-6.19-0.704c-1.034,0.087-3.014,0.158-4.012,0.213
			c-2.881,0.158-8.212,0.185-10.806,0.091c-0.51,0.002-1.018-0.002-1.524-0.01c-1.001-0.018-1.994-0.053-2.977-0.103
			c-4.676-0.247-9.099-0.859-13.051-1.588c-17.511-3.229-28.076-11.821-34.901-22.717
			C370.141,776.738,368.906,753.059,370.815,743.746z"
                    />
                    <path
                        fill="#CE8123"
                        d="M372.877,750.247c-0.179,5.524,25.104,21.748,39.545,27.978c3.716,1.603,7.918,2.938,11.955,4.105
			c4.789,1.386,9.299,2.514,12.736,3.151c0.494-0.83,0.688-2.124,0.169-3.841c-5.501-6.761-12.142-21.398-12.794-30.013
			c0.557-0.644,1.519-1.071,3.115-1.069c10.248,6.621,22.486,13.762,33.432,18.719c11.588,5.244,32.602,12.344,50.775,17.771l0,0
			c1.504-0.75,3.473-1.254,5.74-1.197c0.808,0.021,1.637,0.022,2.484,0.059c1.031-0.197,2.374-2.446,5.282-3.335
			c4.855-1.484,17.017-0.15,25.962-1.045c2.354-0.235,4.453-0.573,6.049-1.119c5.526-1.915,13.141-6.45,14.961-9.039
			c1.186,8.002-4.756,22.178-7.779,26.177c-5.044,6.67-11.917,10.393-18.975,11.94c-2.31,0.507-5.258,0.796-8.418,0.883
			c-8.889,0.279-19.28-0.921-22.096-1.917c-0.438-0.154-1.079,0.175-1.702,0.789c-0.093,1.98-0.095,4.323,0.315,6.708
			c0.174,1.012,0.402,2.034,0.754,3.027c2.623,7.409,11.502,16.521,18.943,17.983c1.807,0.355,3.653,0.555,5.426,0.727
			c0.998,0.097,2.009,0.179,3.031,0.221c6.332,0.259,12.924-1.289,18.144-0.931c4.786,0.329,12.424,1.989,14.485,3.517
			c-3.521-0.497-8.547-0.236-10.645,2.103c-1.686,1.88-2.994,3.163-4.862,4.387c-0.514,0.337-1.045,0.609-1.517,0.979
			c-1.03,0.81-2.235,1.465-3.771,2.082c-2.179,0.876-5.025,1.675-9.034,2.65c-2.798,0.681-5.688,1.278-8.773,1.873
			c-10.377,1.999-19.314,1.878-30.115-1.236c-2.04-0.588-4.013-1.195-5.914-1.867c-16.016-5.617-27.342-15.031-37.469-28.647
			c-1.193-1.454-5.377-6.596-6.258-7.858c-1.173,0.151-4.418-2.231-5.529-2.138c-0.688,0.058-3.294,0.104-3.959,0.142
			c-1.921,0.105-8.939,0.123-10.668,0.061c-0.502,0.021-1.004,0.037-1.506,0.047c-0.981,0.018-1.963,0.014-2.939-0.008
			c-4.588-0.107-9.054-0.612-12.924-1.089c-17.841-2.196-28.133-10.118-35.268-20.812
			C375.436,779.424,371.604,756.456,372.877,750.247z"
                    />
                    <path
                        fill="#E3A21E"
                        d="M374.938,756.748c-0.089,2.763,27.418,21.026,39.397,25.739c3.432,1.351,7.611,2.413,11.731,3.249
			c4.607,0.935,9.508,3.315,12.561,1.699c1.697-0.898,3.079-1.62,3.138-3.115c-4.556-5.376-13.616-19.166-15.073-27.836
			c-0.279-1.664,0.177-3.017,1.484-3.705c9.874,6.436,22.118,13.881,31.966,18.359c9.514,4.322,32.512,11.87,50.863,17.479l0,0
			c1.906-0.014,4,0.119,6.158,0.426c0.831,0.118,1.656,0.271,2.479,0.412c0.516-0.099,1.351-3.216,4.391-4.668
			c3.515-1.68,16.938-0.327,25.93-0.9c2.51-0.16,4.652-0.431,6.076-0.932c5.514-1.957,14.445-7.225,15.355-8.52
			c0.593,4.002-6.958,19.555-8.64,21.714c-5.285,6.784-12.335,10.424-19.487,11.595c-2.131,0.349-5.198,0.474-8.585,0.426
			c-9.345-0.109-21.015-1.444-22.422-1.942c-0.22-0.077-0.939,0.515-1.323,1.685c-0.593,1.806-0.607,4.296-0.397,6.828
			c0.086,1.034,0.235,2.091,0.529,3.125c1.769,6.218,10.684,16.233,18.219,19.955c1.838,0.907,3.669,1.586,5.341,2.024
			c1.011,0.265,2.026,0.46,3.049,0.573c6.688,0.739,13.513-2.149,18.289-2.553c4.908-0.415,14.086,0.994,15.117,1.758
			c-1.76-0.248-7.714-0.048-9.801,2.959c-1.54,2.219-3.141,3.136-4.797,3.891c-0.57,0.26-1.112,0.444-1.539,0.76
			c-1.038,0.767-2.205,1.293-3.68,1.752c-2.098,0.652-4.821,1.169-8.723,1.989c-2.845,0.599-5.589,1.124-8.362,1.591
			c-9.38,1.579-17.25,1.391-28.457-2.272c-1.928-0.63-3.778-1.257-5.558-1.923c-14.572-5.428-24.488-12.971-35.009-27.709
			c-0.597-0.728-5.835-7.045-6.275-7.676c-0.587,0.076-4.313-3.619-4.868-3.572c-0.344,0.028-3.575,0.052-3.908,0.07
			c-0.96,0.053-9.666,0.062-10.53,0.03c-0.493,0.042-0.989,0.076-1.487,0.104c-0.961,0.052-1.931,0.079-2.901,0.087
			c-4.499,0.032-9.009-0.356-12.796-0.59c-18.177-1.121-28.104-8.467-35.634-18.906C380.86,782.029,374.303,759.854,374.938,756.748
			z"
                    />
                    <path
                        fill="#FFD800"
                        d="M566.25,782.5c0,0-6.5,12.25-12.25,16.5c-6.038,4.463-16.983,5.923-23.141,6.56
			c-6.156,0.638-26.75-1.698-26.75-1.698s-2.548,5.521-1.486,10.828c1.062,5.307,15.583,22.338,22.377,25.311
			c6.793,2.972,15.504,4.312,19.75,3.25s12.75-2,12.75-2s-5.871,0.109-7.606,3.239s-4.291,2.617-5.344,3.342
			c-2.157,1.484-4.709,1.367-10.19,2.335c-10.828,1.911-17.371-0.245-30.109-4.916c-13.778-5.052-24.946-13.907-35.383-29.075
			l-8.917-10.615h-12.102c-4.764,0.611-10.241,0.213-14.437,0.213c-15.719,0-29.232-5.28-36.162-13.772
			c-3.271-4-11.75-19.25-11.75-19.25s24.183,13.49,32.25,16.25s30.948,3.821,32.646,0.425c-4.033-4.458-20.646-21.425-14.861-27.175
			c3.715,2.25,18.471,11.889,25.901,15.286c7.431,3.396,42.569,11.668,58.063,16.464c0,0,3-3.25,5.75-5.25s14.5-0.25,23-0.75
			S566.25,782.5,566.25,782.5z"
                    />
                </g>
                <g>
                    <path
                        fill="#F15D32"
                        stroke="#000000"
                        strokeWidth="3"
                        d="M632.412,683.582c-12.665-7.092-69.803-35.65-116.666-44.338
			c7.49-13.947,44.582-16.088,60.668-18.666s44.795-18.686,35.006-37.332c17.109,9.875,13.08,25.254,9.08,37.254
			s-17.375,23.25-12.375,23.25c7.5,0,9.731-4.979,20.375-6.25c9.826-1.652,16.104-7.807,13.255-16.922
			C653.376,632.617,646.074,677.953,632.412,683.582L632.412,683.582z"
                    />
                    <path
                        fill="#F5842F"
                        d="M630.934,681.187c-17.623-10.569-73.977-36.487-109.124-43.003c5.017-9.342,34.35-11.57,50.884-14.379
			c1.884-0.32,3.658-0.64,5.27-0.992c15.332-3.412,45.629-18.077,38.287-32.063c11.755,9.229,5.364,16.464,1.625,27.375
			c-0.248,0.716-4.75,8.125-4.75,8.125c-1.625,3.25-2.75,5.25-4.125,7.25c-2.043,2.971-6.034,13.177-4.125,13
			c6.686-0.62,12.341-5.229,21.533-6.847c1.767-0.311,3.362-0.824,4.771-1.311c7.755-2.675,14.435-2.401,12.695-7.968
			C653.466,644.404,641.181,676.965,630.934,681.187z"
                    />
                    <path
                        fill="#FAA727"
                        d="M629.456,678.791c-22.583-14.046-78.151-37.325-101.583-41.669c3.344-6.228,31.718-7.609,46.923-10.919
			c1.625-0.354,3.216-0.723,4.716-1.155c14.591-4.209,43.508-19.6,38.613-28.923c6.399,8.584-1.146,15.678-4.625,25.5
			c-0.245,0.685-4.685,9.238-4.685,9.238s-2.69,3.512-4.065,7.762s-3.363,11.475,0.318,9.362s11.47-4.506,19.248-6.181
			c1.7-0.366,3.203-0.864,4.471-1.244c9.347-2.804,15.736-3.813,15.463-4.688C651.811,651.895,636.287,675.977,629.456,678.791z"
                    />
                    <path
                        fill="#FFCC14"
                        d="M627.979,676.396c-27.542-17.522-82.326-38.163-94.042-40.335c1.672-3.113,29.093-3.598,42.962-7.459
			c1.368-0.381,2.772-0.811,4.163-1.318c13.838-5.05,39.386-18.997,36.938-23.658c1.045,7.939-10.73,20.697-13.947,29.432
			c-0.242,0.653-0.468,1.277-0.645,1.875c-3.376,11.438-1.63,14.735-2.408,15.693c-0.488,0.6,6.256-1.503,8.375-2.125
			c5.058-1.484,6.473-2.85,12.851-4.54c1.629-0.432,3.046-0.892,4.168-1.179c9.799-2.506,17.062-1.426,16.481-3.281
			C648.405,657.51,631.394,674.988,627.979,676.396z"
                    />
                    <path
                        fill="#FFF200"
                        d="M616,609.5c0,0-24.5,17-37,21.5s-39,4-39,4s54,18,86.5,39c0,0,17.5-11.25,14-31.25
			c0,0-5.768,1.744-16.625,4C618.155,647.938,600,654,600,654s-5.285-1.963-2-15C599.835,631.715,611.34,617.387,616,609.5z"
                    />
                </g>
                <g>
                    <path
                        fill="#EF4431"
                        stroke="#000000"
                        strokeWidth="3"
                        strokeMiterlimit={10}
                        d="M634.667,764.001
			c0,0-25.913,4.91-35.667,23.333s-24.588,31.012-34.254,33.907c28.683,10.272,42.909,5.636,52.588-9.574
			C629,793.334,634.667,764.001,634.667,764.001z"
                    />
                    <path
                        fill="#F36D33"
                        d="M630.917,768.084c0,0-4,25.792-14,41.416c-8.509,13.283-24.971,18.76-46.482,11.056
			c7.249-2.171,21.083-15.488,29.065-30.306C607.482,775.434,626.542,770.459,630.917,768.084z"
                    />
                    <path
                        fill="#F68D34"
                        d="M627.167,772.168c0,0-2.333,22.25-10.667,35.166c-7.339,11.355-26.035,17.673-40.377,12.537
			c4.833-1.448,17.666-15.493,23.877-26.704S618.417,776.918,627.167,772.168z"
                    />
                    <path
                        fill="#FBAC35"
                        d="M623.417,776.251c0,0-0.667,18.708-7.333,28.917c-6.17,9.428-27.102,16.586-34.272,14.018
			c2.417-0.725,14.25-15.497,18.688-23.102C604.938,788.479,610.292,783.376,623.417,776.251z"
                    />
                    <path
                        fill="#FFCD33"
                        d="M619.667,780.334c0,0,1,15.167-4,22.667S587.5,818.5,587.5,818.5s10.833-15.501,13.5-19.5
			S602.167,789.834,619.667,780.334z"
                    />
                </g>
                <path
                    fill="#CB9966"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M466.755,534.246
		c34.009-12.447,75.613-39.838,65.331-70.002c-11.745-31.422-53.045-21.087-91.004-4.662L466.755,534.246L466.755,534.246z"
                />
                <path
                    fill="#FFFFFF"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M441.082,459.582
		c-38.832,14.375-76.999,18.666-76.999,18.666l-86.333-84.006c-46.133-34.588-103.227,18.628-58.333,60.669
		c42.316,40.181,67.657,65.235,79.335,81.671c13.852,16.111,49.124,36.277,79.334,30.334c29.224-8.09,56.026-20.176,88.668-32.67
		L441.082,459.582L441.082,459.582z"
                />
                <path
                    fill="#FFFFFF"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M91.082,694.416
		c0,0,1.552-241.698,0-267.504c-2.193-94.099,196.69-82.401,196.001,0c0.688,81.395,0,267.504,0,267.504H91.082z"
                />
                <path
                    fill="#FFFFFF"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M263.751,580.91
		c-40.318-5.336-74.668-21.001-74.668-21.001l-32.666-116.667c-23.66-51.929-99.709-34.383-81.666,23.338
		c16.406,57.099,25.834,91.449,27.999,111.995c3.684,20.394,24.159,55.495,53.667,65.34c28.996,7.71,58.238,10.634,93.334,16.331
		L263.751,580.91L263.751,580.91z"
                />
                <polygon
                    fill="#EE2A24"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="
		121.417,361.577 280.082,361.577 203.083,471.241 121.417,361.577 	"
                />
                <path
                    fill="#EE2A24"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M121.417,361.577
		l9.333,14c0,0-6.765,56.681-58.333,41.999L121.417,361.577L121.417,361.577z"
                />
                <polygon
                    fill="#EE2A24"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="
		121.417,361.577 74.752,387.245 79.418,338.245 121.417,361.577 	"
                />
                <path
                    fill="#CB9966"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M249.752,660.246
		c35.096,6.01,84.818,3.085,90.999-28c5.516-33.277-35.428-44.975-76.999-51.336L249.752,660.246L249.752,660.246z"
                />
                <path
                    fill="#CB9966"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M249.752,242.575h-112
		v70.001c0.584,30.605,25.592,55.604,55.998,56.003c31.279-0.399,56.278-25.397,56.002-56.003V242.575L249.752,242.575z"
                />
                <path
                    fill="#FFFFFF"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M249.752,242.575h-112
		c0.584-23.831,0-77,0-77c-19.615,9.295-66.417-11.175-58.333-55.998c9.471-42.934,35.793-45.858,51.331-39.667
		c-12.613-23.741,16.634-70.534,58.333-65.335c40.199,6.499,63.598,41.595,49,67.667c29.219-14.375,76.012,3.176,67.666,42.003
		c-9.2,37.232-29.675,57.702-55.998,49V242.575L249.752,242.575z"
                />
                <path
                    fill="#FFFFFF"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M490.082,592.579
		c18.979,13.481,48.469,13.481,67.667,0c17.175-12.438,17.175-33.458,0-46.665c-19.198-12.723-48.688-12.723-67.667,0
		C472.68,559.121,472.68,580.142,490.082,592.579L490.082,592.579z"
                />
                <path
                    fill="#7F99B2"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M574.079,436.245
		c-27.562-18.637-72.395-18.637-100.327,0l21.002,107.169c17.913-11.414,43.797-10.223,62.995,2.5L574.079,436.245L574.079,436.245z
		"
                />
                <path
                    fill="#CCCCCC"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M515.746,587.908
		c0.902,4.548,16.994,5.213,18.676,0C534.193,582.895,516.648,583.626,515.746,587.908L515.746,587.908z"
                />
                <path
                    fill="#CCCCCC"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M494.754,580.91
		c-2.127,4.197,12.817,10.197,16.33,4.672C512.356,581.945,495.57,576.781,494.754,580.91L494.754,580.91z"
                />
                <path
                    fill="#CCCCCC"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M555.413,580.91
		c2.497,3.476-12.456,9.467-13.995,4.672C538.172,581.215,554.957,576.059,555.413,580.91L555.413,580.91z"
                />
                <path d="M192.5,324.336c-0.211,0-0.211,0.328,0,0.328S192.711,324.336,192.5,324.336z" />
                <path
                    d="M189.655,315.644c-2.66-0.592-5.321-0.977-8.655-0.31s-5.984,1.986-8.833,3c-3.851,1.37-8.54,2.97-13,3.667
		s-12.838-3.17-12.591-2.751c3.814,6.468,13.2,8.669,20.124,7.525c4.717-0.779,5.53-0.84,9.967-2.607
		c1.396-0.556,4.746-1.617,7.013-1.17c1.945,0.384,3.995,0.577,5.975,0.631C193.333,323.667,193.167,316.333,189.655,315.644z"
                />
                <path
                    d="M199.512,315.644c2.66-0.592,5.321-0.977,8.655-0.31s5.984,1.986,8.833,3c3.851,1.37,8.54,2.97,13,3.667
		s12.838-3.17,12.591-2.751c-3.814,6.468-13.2,8.669-20.124,7.525c-4.717-0.779-5.53-0.84-9.967-2.607
		c-1.396-0.556-4.746-1.617-7.013-1.17c-1.945,0.384-3.995,0.577-5.975,0.631C195.833,323.667,196,316.333,199.512,315.644z"
                />
                <path d="M175,270.055c-8.958,0-8.958,13.891,0,13.891S183.958,270.055,175,270.055z" />
                <path d="M214.833,270.388c-8.958,0-8.958,13.891,0,13.891S223.791,270.388,214.833,270.388z" />
                <path d="M317,101" />
                <path
                    fill="#FFFFFF"
                    stroke="#000000"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M67,105"
                />
                <line fill="none" x1="-363.43" y1="278" x2="-383" y2="290.5" />
                <line fill="none" x1="-163" y1="150" x2="-196" y2="171.08" />
            </g>
        </svg>
    );
}
